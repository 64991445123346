import { graphql } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import React from "react"

import Layout from "../../components/Layout"
import Link from "../../components/Link"
import Seo from "../../components/Seo"

export default function Article({ data }) {
  const post = data.ghostPost
  const tags = post.tags

  return (
    <Layout>
      <Seo
        title={post.metaTitle ? post.metaTitle : post.title}
        description={
          post.metaDescription ? post.metaDescription : post.description
        }
        remoteImage={post.featureImage}
        article
      />
      <article className="section bg-dark pt-16 md:pt-20 text-white items-start">
        <section className="w-full lg:max-w-screen-md lg:mx-auto">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 w-full gap-4 sm:gap-8 md:gap-6 lg:gap-20 mb-4 md:mb-8">
            <div className="relative h-0 w-full pb-full">
              <div className="absolute inset-0">
                {post.localImage ? (
                  <GatsbyImage
                    image={getImage(post.localImage)}
                    className="h-full w-full"
                    quality={80}
                    loading="eager"
                  />
                ) : (
                  <StaticImage
                    src="../../images/articles-default.jpg"
                    className="h-full w-full"
                    quality={80}
                    loading="eager"
                  />
                )}
              </div>
            </div>
            <div className="space-y-4 md:space-y-4 sm:order-first md:order-none">
              <h1 className="mb-0 text-left lg:text-6xl lg:-mt-2.5 text-primary-lighter">
                {post.title}
              </h1>
              <div className="text-white text-sm md:text-base text-opacity-60 space-y-2">
                <div className="flex flex-row space-x-2">
                  {tags.map(tag => (
                    <Link to={"/articles/" + tag.slug}>{tag.title}</Link>
                  ))}
                </div>
                <p>{post.publishedAt}</p>
                <AnchorLink
                  to={"/articles/" + post.slug + "#post"}
                  className="hidden sm:block md:hidden"
                  stripHash
                >
                  Start Reading
                </AnchorLink>
              </div>
            </div>
          </div>
          <section
            id="post"
            dangerouslySetInnerHTML={{ __html: post.html }}
            className="leading-relaxed flex flex-col justify-center md:text-lg space-y-0"
          />
          <div className="flex flex-col mt-2 md:mt-8 md:text-lg md:space-y-1 text-white text-opacity-60">
            <p>Written by</p>
            <div className="flex flex-row space-x-2"></div>
            {post.authors.map(author => (
              <p>{author.name}</p>
            ))}
          </div>
        </section>
      </article>
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String) {
    ghostPost(slug: { eq: $slug }) {
      authors {
        profileImage: profile_image
        name
      }
      localImage {
        childImageSharp {
          gatsbyImageData
        }
      }
      title
      html
      readingTime: reading_time
      publishedAt: published_at(formatString: "Do MMMM, YYYY")
      tags {
        title: name
        slug
      }
      slug
      excerpt
      featureImage: feature_image
      metaDescription: meta_description
      metaTitle: meta_title
    }
  }
`
