import AniLink from "gatsby-plugin-transition-link/AniLink"
import React from "react"

export default function Link(props) {
  return (
    <AniLink
      cover={props.cover}
      direction={props.direction}
      bg={props.bg}
      to={props.to}
      className={props.className}
      activeClassName={props.activeClassName}
      duration={props.duration}
    >
      {props.children}
    </AniLink>
  )
}

Link.defaultProps = {
  cover: "true",
  direction: "up",
  bg: "#ffffff",
  to: "#",
  duration: "1.25",
}
